<template lang="pug">
    .spread-wrap
        .chart(aria-hidden="true")
            img(src="../../../../../assets/images/articles/6/spread/chart.svg", alt="", loading="lazy")
        .content-wrap
            article-icon.content-icon(src="hex_green", class="opacity-50 pulse" :style="{ '--shift': 0.95, ...style.rect }")
            article-icon.content-icon(src="hex_green", class="opacity-50 pulse" :style="{ '--shift': -0.75, ...style.rect }")
            article-icon.content-icon(src="hex_green", class="opacity-50 pulse" :style="{ '--shift': -1, ...style.rect }")
            article-icon.content-icon(src="hex_green", class="opacity-50 pulse" :style="{ '--shift': 1.5, ...style.rect }")
            .content-item-wrap(
                v-for="(contentItem, index) in contentData",
                :key="index"
            )
                .content-item-illn(v-if="index === 1", aria-hidden="true", :style="{ '--shift': -0.35, ...style.rect }")
                    img(src="../../../../../assets/images/articles/6/spread/illn.svg", alt="", loading="lazy")
                .content-item
                    ui-paragraph.text.dark(
                        v-for="text in contentItem",
                        :key="text",
                        v-html="text"
                    )
</template>

<script>
import { getInertedMouse } from '../../../../../assets/js/utils';

import ArticleIcon from '../../../Icon.vue';
import UiParagraph from '../../../../ui/Paragraph.vue';

export default {
    name: 'spread',
    components: {
        ArticleIcon,
        UiParagraph,
    },
    computed: {
        contentData() {
            /* eslint-disable max-len */
            return [
                [
                    '<strong>Согласно Докладу ВОЗ о&nbsp;глобальной борьбе с&nbsp;туберкулезом, Россия занимает 20-е место по&nbsp;числу новых случаев туберкулезной инфекции.</strong>',
                    'По&nbsp;данным Министерства здравоохранения&nbsp;РФ, заболеваемость в&nbsp;2018 году составила около 65&nbsp;тысяч человек.',
                    'В&nbsp;2019 году заболеваемость снизилась на&nbsp;7% (зарегистрировано 60,5 тысяч новых случаев). Всего на&nbsp;учете в российских туберкулезных диспансерах на&nbsp;декабрь 2019 года состояло 126&nbsp;737&nbsp;человек.',
                ],
                [
                    '<em>При этом заболеваемость на&nbsp;100 тысяч населения в&nbsp;России составляет 41,2&nbsp;человека,</em> что существенно ниже среднего общемирового значения.',
                    'После резкого взлета заболеваемости в&nbsp;1990-х последовало улучшение&nbsp;&mdash; <em>эпидемиологическая ситуация по&nbsp;туберкулезу в&nbsp;России в&nbsp;последние годы улучшалась.</em> Выдержит&nbsp;ли этот тренд испытание коронавирусной пандемией, неизвестно.',
                ],
            ];
            /* eslint-enable max-len */
        },
        style() {
            const { x, y } = this.mouse;
            const rectX = x * 12;
            const rectY = y * 12;

            return {
                rect: {
                    transform: `translate(calc(${rectX}px * var(--shift)), calc(${rectY}px * var(--shift)))`,
                },
            };
        },
    },
    data: () => ({
        active: 0,
        mouse: {
            x: 0,
            y: 0,
        },
    }),
    methods: {
        handleMouse(ev) {
            const { x, y } = getInertedMouse(ev, this.mouse);

            this.mouse.x += x;
            this.mouse.y += y;
        },
    },
    mounted() {
        if (!this.$platform.isMobile) {
            document.body.addEventListener('mousemove', this.handleMouse);
        }
    },
    beforeDestroy() {
        if (!this.$platform.isMobile) {
            document.body.removeEventListener('mousemove', this.handleMouse);
        }
    },
};
</script>

<style lang="scss" scoped>
.spread-wrap {
    width: 100%;
    max-width: 750px;
    margin: 0 auto rem(100) auto;

    .chart {
        width: 100%;
        margin: rem(24) 0;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .content-wrap {
        width: 100%;
        margin: rem(142) 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        .content-icon {
            position: absolute;

            &:nth-child(1) {
                width: rem(30);
                height: rem(26);
                top: -20%;
                right: -20%;
            }

            &:nth-child(2) {
                width: rem(52);
                height: rem(44);
                top: 100%;
                left: -20%;
            }

            &:nth-child(3) {
                width: rem(30);
                height: rem(26);
                top: 130%;
                left: 40%;
            }

            &:nth-child(4) {
                width: rem(44);
                height: rem(38);
                top: 145%;
                right: 15%;
            }
        }

        .content-item-wrap {
            width: 45%;

            .content-item {
                width: 100%;
                padding: rem(24);
                background-color: var(--light-green);
                border-radius: rem(16);

                .text {
                    margin: rem(24) 0;

                    &.dark {
                        &::v-deep {
                            em {
                                color: white;
                                background-color: var(--teal);
                            }
                        }
                    }
                }
            }

            .content-item-illn {
                width: rem(72);
                margin-bottom: rem(48);
                position: relative;
                top: rem(-24);

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: 0 auto rem-mobile(100) auto;
        padding: 0 var(--offset-x);

        .chart {
            margin: rem-mobile(32) 0;
        }

        .content-wrap {
            margin: rem-mobile(64) 0;
            display: block;

            .content-icon {
                display: none;
            }

            .content-item-wrap {
                width: 100%;

                .content-item {
                    width: 100%;
                    padding: rem-mobile(24);
                    border-radius: rem-mobile(16);

                    .text {
                        margin: rem-mobile(24) 0;
                    }
                }

                .content-item-illn {
                    width: 100%;
                    max-width: rem(120);
                    margin: rem-mobile(48) auto;
                    top: 0;
                }
            }
        }
    }
}
</style>
